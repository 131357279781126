import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Container } from '../../../components/container';
import { Button } from '../../../components/ui/button';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { SvgSelector } from '../../../assets/svg-selector';
import s from './services.module.scss';
import { useNavigate } from "react-router-dom";
export const servicesRef = { current: null };
export const Services = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/services');
    };
    const isMobile = useMediaQuery(750);
    const scroll = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (_jsx("div", { className: s.inner, id: "services", ref: servicesRef, children: _jsxs(Container, { children: [_jsx("div", { className: s.innerHeader, children: _jsx("h4", { className: s.innerTitle, children: t('services.title') }) }), _jsx("div", { className: s.innerTop, children: _jsxs("div", { className: s.innerTopLeft, children: [_jsx("div", { style: { width: '40px', height: '40px' }, children: _jsx(SvgSelector, { id: "notes" }) }), _jsxs("div", { children: [_jsx("p", { className: s.innerTopTitle, children: t('services.smart-title') }), _jsx("p", { className: s.innerTopDescription, children: t('services.smart-description') })] })] }) }), _jsxs(Button, { variant: 'action', style: { width: '100%' }, onClick: handleNavigate, children: [_jsx(SvgSelector, { id: "link" }), t('services.view-services-button')] })] }) }));
};
