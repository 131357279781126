import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Container } from '../../../components/container';
import Decor from '../../../assets/providing/decor.webp';
import s from './providing.module.scss';
export const providingRef = { current: null };
export const Providing = () => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: s.providing, id: "providing", children: [_jsx(Container, { children: _jsxs("div", { className: s.providingInfo, children: [_jsxs("h2", { className: s.providingTitle, ref: providingRef, children: [t('providing.title1'), " ", '  ', " ", _jsxs("span", { children: [" ", t('providing.title2')] }), " ", '  ', " ", t('providing.title3')] }), _jsx("p", { className: s.providingDescription, children: t('providing.description') })] }) }), _jsx("img", { className: s.providingDecor, src: Decor, alt: "decor" })] }));
};
