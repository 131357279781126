import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../../components/container';
import Decor from '../../assets/contacts/decor.webp';
import Message from '../../assets/contacts/message.webp';
import { SvgSelector } from '../../assets/svg-selector';
import s from './contacts.module.scss';
import { Button } from "../../components/ui/button";
import Modal from "../../pages/services/modal";
export const contactsRef = { current: null };
export const Contacts = () => {
    const { t } = useTranslation();
    const [isModalOpen, setModalOpen] = useState(false);
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
    return (_jsxs("div", { className: s.inner, ref: contactsRef, children: [_jsxs(Container, { className: s.innerContainer, children: [_jsxs("div", { className: s.innerRow, children: [_jsxs("div", { className: s.innerLeft, children: [_jsx("h5", { className: s.innerTitle, children: t('contacts.title') }), _jsx("p", { className: s.innerDescription, children: t('contacts.subtitle') }), _jsx("div", { className: s.innerLinks, children: _jsxs("a", { className: `${s.innerLinkMail} ${s.innerLink}`, "aria-label": "mail link", target: "_blank", href: "mailto:technoharmony@yandex.ru", children: [_jsxs("div", { children: [_jsx(SvgSelector, { id: "mail" }), " Mail"] }), _jsxs("div", { children: [_jsx("span", { children: "technoharmony@yandex.ru" }), _jsx(SvgSelector, { id: "chevron-right" })] })] }) }), _jsxs(Button, { variant: 'action', style: { width: '100%', marginTop: '24px' }, onClick: handleOpenModal, children: [_jsx(SvgSelector, { id: "message" }), t('services.contactButton')] })] }), _jsx("div", { className: s.innerRight, children: _jsx("img", { src: Message, alt: "message" }) })] }), _jsx("img", { src: Decor, className: s.innerDecor, alt: "decor" })] }), isModalOpen && _jsx(Modal, { onClose: handleCloseModal })] }));
};
